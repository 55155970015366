<template>
  <div class="deliveries-list">
    <b-card>
      <filter-swapper :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render :key="keyFormRender" :form.sync="formFilter" :fields="fields" @send="simpleFilter" :buttonSend="buttonSend" ref="formFilterDeliveries"
            containerButtonsClass="col-sm-12 col-md-6 container-button mt-2" class="mb-2">
            <template #buttons>
              <b-dropdown class="mx-1" v-b-tooltip.hover :title="$t('Más opciones')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="light"> 
                <template #button-content><feather-icon icon="SettingsIcon"/></template>
                <b-dropdown-item @click="cleanForm">{{$t('Limpiar filtros')}}</b-dropdown-item>
                <b-dropdown-item v-b-modal.modalAvancedFilterDeliveries>{{$t('Búsqueda avanzada')}}</b-dropdown-item>
              </b-dropdown>
              <b-button variant="success" v-if="show.exportButton" @click="callExportFn" :disabled="loading.excel">
                {{$t('Exportar')}}
                <feather-icon v-if="!loading.excel" icon="FileTextIcon"/><i v-else :class="['fa', 'fa-spinner', 'fa-spin']"></i>
              </b-button>
              <b-form-checkbox class="custom-switch-success push-right" :checked="formFilter.sameday" switch v-model="formFilter.sameday" @change="filterForm(formFilter)"> {{$t('Servicio Sameday')}}</b-form-checkbox>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div class="mb-1">              
          <p v-if="linkToDownload" class="text-right p-0 m-0">La exportación ha finalizado exitosamente: <a :href="linkToDownload" class="text-primary" :download="downloadName">Descargar aquí</a></p>
          <p v-if="ErrorDownloadLink" class="text-right p-0 m-0 font-weight-bold">{{ ErrorDownloadLink }}</p>
      </div>
      <div v-show="!loading.first">
        <table-render id="table-deliveries" :schema="schema" :rows.sync="rows" :loading="loading.deliveries" :sortByCustom="orderBy">
          <template #current_status="scope">
            <span :class="`table_dot--state ${$options.filters.getStatusClasses()[scope.rowdata.delivery_status_id]}`"></span> {{scope.rowdata.status_name}}
          </template>
          <template #imported_id="scope">
            <a
              :href="generateUrl(scope.rowdata.id)"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ scope.rowdata.imported_id }}
            </a>
          </template>
          <template #tracking_number="scope">
            {{scope.rowdata.tracking_number || "sin info."}}
          </template>
          <template #bodega="scope">
            {{scope.rowdata.warehouse_address || "-"}}
          </template>
          <template #customer="scope">
            <span v-if="!scope.rowdata.customer_name">Sin info.</span>
            <a v-else v-b-tooltip.click.html :title="(scope.rowdata.customer_contact)" class="lnk underline-text-deliveries">{{scope.rowdata.customer_name}}</a>
          </template>
          <template #place_name="scope">
            {{[2,3,4,5,6].includes(scope.rowdata.country_id) ? (scope.rowdata.place_level_1_name+', ' || '')+scope.rowdata.place_name : scope.rowdata.place_name}}
          </template>
          <template #estimated_price="scope">
           <span v-if="scope.rowdata.estimated_price">{{countryCurrency}}{{scope.rowdata.estimate_price || scope.rowdata.estimated_price}}</span>
          </template>
          <template #pickup_date="scope">
            {{scope.rowdata.pickup_date || "sin info."}}
          </template>
          <template #created_at="scope">
            {{scope.rowdata.created_country_timezone_formatted || scope.rowdata.created_at_formatted}}
          </template>
          <template #deadline_date="scope">
            {{scope.rowdata.deadline_date_formatted || "-"}}
          </template>
          <template #assigned_slot="scope">
            {{scope.rowdata.assigned_slot || "-"}}
          </template>
        </table-render>
        <pagination
          :pagination="pagination"
          :noDigits="true"
          :showSize="true"
          :disabled="loading.billing"
        />
      </div>
      <b-skeleton type="input" v-if="loading.first" class="mb-2 mt-2 spacing-label-field"/>
      <div class="table-render-skeleton" v-if="loading.first">
        <b-skeleton-table
          :rows="pagination.deliveries || 10"
          :columns="schema.length || 10"
          :table-props="{ }"/>
      </div>
    </b-card>
    <modal-avanced-filter @filter="collectFullFilter" @setLink="setDownloadLink" @showAlert="showAlert" :date="formFilter.date_range" :countries="countries" ref="modalAvancedFilterDeliveries"></modal-avanced-filter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalAvancedFilter from '../ModalAvancedFilter'
import DeliveriesListService from './deliveriesList.service.js'

export default {
  components: {ModalAvancedFilter},
  data () {
    return {
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      fields: [
        { fieldType: 'FieldDatepicker', name: 'date_range', label: this.$t('Fecha'), range: true, containerClass: 'col-sm-12 col-md-3' },
        { fieldType: 'FieldInput', label: this.$t('N. de envío'), name: 'number', placeholder: this.$t('Buscar envío'), containerClass: 'col-sm-12 col-md-3' }
      ],
      initialDateRange: { start: this.$options.filters.momentSubtract(35), end: this.$options.filters.momentAdd() },
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      schema: [],
      rows: [],
      formFilter: {},
      pagination: {
        page: 1,
        total: 10,
        limit: 20
      },
      keyFormRender: 0,
      loading: {
        first: true,
        deliveries: false,
        excel: false
      },
      countryCurrency: '$',
      show: {
        exportButton: false
      },
      switchButtons: {
        sameday: false
      },
      userData: null,
      linkToDownload: null,
      downloadName: null,
      ErrorDownloadLink: null,
      deliveriesListService: new DeliveriesListService(this),
      firstCall: true
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      countries: 'getCountries',
      //MIGRA
      deliveriesPlat: 'getDeliveriesPlat'
    })
  },
  mounted () {
    this.initialLoad()
    this.firstCall = false
  },
  watch: {
    mySession() {
      if (this.mySession.id && this.firstCall) this.initialLoad()
    },
    deliveriesPlat () {
      if (this.deliveriesPlat.is_estimated_price) {
        this.countryCurrency = this.$i18nImport(`${this.userData.country.code.toLowerCase()}.es`)[`${this.userData.country.code.toLowerCase()}.es`].currency_country
        this.schema.splice(this.getElementIndex(this.schema, 'carrier_name') + 1, 0, {label: 'Valor estimado', key: 'estimated_price', useSlot: true})
      }
      if (this.deliveriesPlat.can_view_slots) {
        this.schema.splice(this.getElementIndex(this.schema, 'deadline_date'), 1, {label: this.$t('Slot asignado'), key: 'assigned_slot', useSlot: true, customSort: 'entel_slot'})
      }
      this.rows = this.deliveriesPlat.rows
      if (!this.loading.first && this.deliveriesPlat.total > 0 && (this.$session.get('cas_user')?.profile_type !== 2 && !this.$session.get('cas_user')?.special_permissions.includes('store-profile'))) this.show.exportButton = true
      this.loading.first = false
      this.loading.deliveries = false
      // this.pagination.page = Number(this.deliveriesPlat.pagination.page)
      // this.pagination.total = Number(this.deliveriesPlat.pagination.total)
      // this['pagination.limit'] = Number(this.deliveriesPlat.pagination.pages)
      this.$bvModal.hide('modalAvancedFilterDeliveries')
    },
    'pagination.page'() {
      this.getDeliveries()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getDeliveries()
    }
  },
  methods: {
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
      this.formFilter[name] = null
    },
    initialLoad () {
      this.userData = this.$session?.get('cas_user')
      this.setInitialData()
      this.filterList(this.formFilter)
    },
    setInitialData () {
      this.formFilter = {
        date_range: this.initialDateRange,
        sameday: false
      }
      this.schema = [
        {label: this.$t('Estado'), key: 'current_status', useSlot: true},
        {label: this.$t('N. de envío'), key: 'imported_id', useSlot: true, wrap: true},
        {label: this.$t('Orden de flete'), key: 'tracking_number', useSlot: true},
        {label: this.$t('Bodega'), key: 'bodega', useSlot: true},
        {label: this.$t('Cliente'), key: 'customer', useSlot: true},
        {label: this.$t('Comuna'), key: 'place_name', useSlot: true},        
        {label: this.$t('Courier'), key: 'carrier_name', wrap: true},
        {label: this.$t('Fecha creación'), key: 'created_at', useSlot: true, wrap: true},
        {label: this.$t('Fecha compromiso'), key: 'deadline_date', useSlot: true, wrap: true}
      ]
      if (['marketplace', 'superadmin', 'admin'].includes(this.$session?.get('cas_user')?.role)) {
        this.schema.unshift({label: this.$t('Empresa'), key: 'company_name'})
      }
      if ([10, 1162].includes(this.$session.get('cas_user')?.dubai_organization_id)) {
        this.schema.splice(this.getElementIndex(this.schema, 'created_at'), 0, {label: this.$t('Fecha de retiro'), key: 'pickup_date', wrap: true})
      }
      this.changeTextI18n(this.$session.get('cas_user')?.country.code, 'place_name', 'Place_id')
     
      this.environment = this.deliveriesListService.setEnvironment()
    },
    generateUrl(id) {
      return this.$router.resolve({ name: 'deliveries-details', params: { id } }).href
    },
    filterForm (form) {
      this.filterList({...this.advancedFilters, ...form})
    },
    filterList (data, isExportation = false) {
      this.filters = {}
      Object.keys(data).map(key => {
        if (key !== 'date_range') {
          if (key === 'company') {
            if (this.$refs.modalAvancedFilterDeliveries.setIsMarketplace()) {
              this.filters.marketplace = Number(data[key].id)
            } else {
              this.filters.company = Number(data[key].id)
            }
          }
          else if (['carrier', 'pudo', 'country', 'place', 'place_id', 'type', 'multi_package'].includes(key) && data[key]?.id) this.filters[key] = Number(data[key]?.id)
          else if (key === 'service') this.filters[key] = data[key].code
          else if (key === 'place_level_1') this.filters[key] = data[key].name
          else if (key === 'flow_action') this.filters[key] = data[key].map(action => action.flow_action).join('-')
          else if (key === 'contract_type') this.filters[key] = data[key].map(action => action.id).join(',')
          else if (key === 'sellers') this.filters[key] = data[key].map(data => Number(data.id))
          else if (key === 'sameday' && data[key]) this.filters[key] = 1
          else if (key === 'number') this.filters[key] = data[key]
          else if (key === 'email') this.filters[key] = data[key]
          else if (key === 'customer') this.filters[key] = data[key]
          else if (key === 'later_status' && data[key]) this.filters[key] = 1
          if (data.service?.code && data.carrier?.dispatch_in) {
            this.filters.dispatch_in = data.carrier.dispatch_in
            this.filters.service = (data.carrier.dispatch_in === 'akihabara') ? data.service.code : data.service.id
          }
        } else {
          this.filters.origin_created_at_from = this.$options.filters.moment(data.date_range.start, 'YYYY-MM-DD')
          this.filters.origin_created_at_to = this.$options.filters.moment(data.date_range.end, 'YYYY-MM-DD')
          this.formFilter.date_range = { start: data.date_range.start, end: data.date_range.end } 
        }
      })
      if (!isExportation) { 
        this.pagination.page !== 1 ? this.pagination.page = 1 : this.getDeliveries()
      } else {
        //Se llama desde aqui para poder tomar el dato del filtro simple tambien
        this.callExportFn()
      }
    },
    callExportFn() {
      this.loading.excel = true
      this.cleanLinks()
      this.$refs.modalAvancedFilterDeliveries.exportData(this.filters)
    },
    setDownloadLink (url, name) {
      this.linkToDownload = url
      this.downloadName = name
    },
    showAlert (msg, des, type) {
      this.$bvToast.toast(msg, {
        title: des,
        variant: type,
        solid: true
      })
      if (type !== 'success') this.ErrorDownloadLink = msg
      this.loading.excel = false
    },
    simpleFilter() {
      this.pagination.page > 1 ? this.pagination.page = 1 : this.filterList(this.formFilter)
    },
    changeOrganization (name, value) {
      if (!value || !value.id) return null
      this.formFilter = {
        ...this.formFilter,
        shipper: null
      }
      const index = this.fields.findIndex(el => el.name === 'shipper_id')
      const queryParams = { paginate_by: 9999, page: 1 }
      const params = { organization_id: value.id }
      this.fields[index].useSkeleton = true
      this.$store.dispatch('fetchService', { name: 'getShippersByOrganization', queryParams, params, onSuccess: () => this.fields[index].useSkeleton = false})
    },    
    getDeliveries () {
      this.loading.deliveries = true
      if (this.linkToDownload) this.linkToDownload = null
      if (this.ErrorDownloadLink) this.ErrorDownloadLink = null
      const queryParams = {
        ...this.filters,
        calls: this.pagination.page,
        qty: this.pagination.limit
      }
      if (this.callWithPagination) queryParams.calls = this.pagination.page - 1
      this.$store.dispatch('fetchService', { name: 'getDeliveriesPlat', queryParams, onError: () => this.loading.deliveries = false})
    },
    goTo (id) {
      this.m_openWinBlank(`/deliveries/${id}`)
    },
    cleanForm () {
      this.formFilter = {
        sameday: false,
        date_range: this.initialDateRange
      }
      this.cleanLinks()
      this.show.exportButton = false
      this.pagination.page > 1 ? this.pagination.page = 1 : this.filterList(this.formFilter)
      this.$refs.modalAvancedFilterDeliveries.cleanForm(true)
    },
    cleanLinks () {
      this.ErrorDownloadLink = null
      this.linkToDownload = null
      this.downloadName = null
    },
    getElementIndex(array, value) {
      return array.findIndex(field => field.key === value)
    },
    changeTextI18n(countryCode = 'CL', elementName, key) {
      const texts = this.$i18nImport(`${countryCode.toLowerCase()}.es`)[`${countryCode.toLowerCase()}.es`]
      const index = this.schema.findIndex(el => el.key === elementName)
      this.schema[index].label = this.$t(texts[key])
    },
    collectFullFilter (data, isExport) {
      this.advancedFilters = data
      this.filterList({...this.formFilter, ...this.advancedFilters}, isExport)
    },
    orderBy (sortAs, type) {
      if (type === null) {
        if (this.filters.propertyName) delete this.filters.propertyName
        if (this.filters.desc) delete this.filters.desc
      } else {
        this.filters = {
          ...this.filters,
          propertyName: sortAs,
          desc: !!(type === 'desc')
        }
      }
      this.getDeliveries()
    }
  }
}
</script>

<style lang='scss'>
.underline-text-deliveries{
  text-decoration: underline !important;
}
.custom-switch-success {
  margin-top: 0.5rem;
  > label {
    font-size: 14px !important;
  }
}
</style>
