<template>
	<div>
		<b-modal id="modalAvancedFilterDeliveries" modal-class="dialog-1000 custom-dialog"
    hide-footer no-close-on-backdrop centered @close="close" :title="$t('Búsqueda avanzada')">
			<div class="mt-0">
				<form-render :key="keyFormRender" :fields="fields" :form.sync="formDeliveriesFilter" ref="formRenderAvancedFilter" containerButtonsClass="col-sm-12" /> 
        <div class="d-flex justify-content-end">
          <b-button class="m-1" variant="warning" @click="ok" :disabled="loading.exporting">
            <span class="mb-1">{{$t('Filtrar')}}</span>
          </b-button>

          <b-button v-if="showExportBtn" class="mt-1 mb-1" variant="success" @click="e => ok(e, true)" :disabled="loading.exporting">
            <span class="mb-1">{{$t('Exportar')}} <feather-icon v-if="!loading.exporting" icon="FileTextIcon"/><i v-else :class="['fa', 'fa-spinner', 'fa-spin']"></i></span>
          </b-button>
        </div>
        <div>
          <p v-if="!!linkToDownload" class="text-right">La exportación ha finalizado exitosamente: <a :href="linkToDownload" :download="downloadName">descargar aquí</a></p>
          <p v-if="ErrorDownloadLink" class="text-right">{{errorMsg}}</p>
        </div>
			</div>
		</b-modal>
	</div>
</template>
<script>

import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'

export default {
  props: ['filter', 'setLink', 'showAlert', 'date'],
  data () {
    return {
      formDeliveriesFilter: {},
      fields: [],
      keyFormRender: 0,
      deliveryStatuses: [],
      destinationType: Object.freeze([
        { id: 1, text: 'Domicilio' },
        { id: 2, text: 'Punto de retiro' }
      ]),
      contractTypes: Object.freeze([
        { id: 'pasarela', text: 'Pasarela' },
        { id: 'distribucion', text: 'Distribución' },
        { id: 'pasarela-pro', text: 'Pasarela-Pro' }
      ]),
      multiPackage: Object.freeze([
        { id: 1, text: 'Monobulto' },
        { id: 2, text: 'Multibulto' }
      ]),
      ID: Object.freeze({
        CHILE: 1,
        MEXICO: 2,
        PERU: 4
      }),
      placeSearchFn: { fx:  e => this.searchInPlaceId(e), nChars: 3, debounce: 600, persistSearch: true},
      linkToDownload: null,
      downloadName:null,
      loading: { exporting: false },
      showExportBtn: false,
      ErrorDownloadLink: false,
      errorMsg: '',
      baseService: new BaseServices(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      countries: 'getCountries',
      deliveryStatusesFull: 'getDeliveryStatusesFull',
      macroStatesPlat: 'getMacroStatesPlat'
    }),
    permissionList() {
      return this.$store.getters.getRelevantEntities
    }
  },
  mounted () {
    if (this.mySession.id) this.setInitialData()
  },
  watch: {
    mySession() {
      if (this.mySession.id) this.setInitialData()
    },
    countries () {
      this.setSelectOptions('country', { options: this.countries })
    },
    macroStatesPlat () {
      this.setSelectOptions('meta_statuses', { options: this.macroStatesPlat.data })
    },
    deliveryStatusesFull () {
      this.deliveryStatuses = this.deliveryStatusesFull.map(statusData => ({
        ...statusData,
        text: statusData.name
      })).filter(status => !['CREATED_REPEATED', 'PRINTED_REPEATED'].includes(status.flow_action))
      this.setSelectOptions('flow_action', { options: this.deliveryStatuses })
    },
    date() {
      this.formDeliveriesFilter.date_range = this.date
    }
  },
  methods: {
    setInitialData () {
      const isNotStoreProfile = this.$session.get('cas_user')?.profile_type !== 2 && !this.$session.get('cas_user')?.special_permissions.includes('store-profile')
      const isAdmin = ['superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)
      this.fields = [
        {fieldType: 'FieldDatepicker', label: this.$t('Rango de fecha'), name: 'date_range', range: true, containerClass: 'col-md-4 container-info col-sm-12' },
        {fieldType: 'FieldInput', label:  this.$t('Correo'), name: 'email', containerClass: 'col-md-4 container-info col-sm-12' },
        {fieldType: 'FieldSelect', label:  this.$t('Punto de retiro'), name: 'pudo', clearable: true, persistSearch: true, searchOnType: { fx: e => this.searchPudos(e), nChars: 3, debounce: 600 }, containerClass: 'col-md-4 container-info col-sm-12'},
        {fieldType: 'FieldSelect', label: this.$t('Comuna'), name: 'place_id', useLabel: true, searchOnType: this.placeSearchFn, containerClass: 'col-12 col-md-4 fieldSelect' },
        {fieldType: 'FieldSelect', label: this.$t('Tipo de destino'), name: 'type', clearable: true, containerClass: 'col-md-4 container-info col-sm-12', options: this.destinationType },
        {fieldType: 'FieldInput', label: this.$t('Nombre de cliente'), name: 'customer', containerClass: 'col-md-4 container-info col-sm-12'},
        {fieldType: 'FieldInput', label: this.$t('Teléfono'), name: 'phone', containerClass: 'col-md-4 container-info col-sm-12'},
        {fieldType: 'FieldSelect', label: this.$t('Courier'), name: 'carrier', clearable: true, containerClass: 'col-md-4 container-info col-sm-12', change: this.handleCarrierChange, searchOnType: { fx:  e => this.searchCarrier(e), nChars: 3, debounce: 600, persistSearch: true }},
        {fieldType: 'FieldSelect', label: this.$t('Servicio'), name: 'service', useLabel: true, dependency: 'carrier', containerClass: 'col-md-4 container-info col-sm-12', searchOnType: { fx: e => this.searchService(e), nChars: 3, debounce: 600, persistSearch: true } },
        {fieldType: 'FieldSelect', label:this.$t('Tipo de bulto'), name: 'multi_package', clearable: true, containerClass: 'col-md-4 container-info col-sm-12', options: this.multiPackage }
      ]
      if (isAdmin) {
        this.fields.splice(this.getElementIndex(this.fields, 'pudo') + 1, 0, {
          fieldType: 'FieldSelect', label: this.$t('País'), name: 'country', addFlags:true, useLabel: true, containerClass: 'col-md-4 container-info col-sm-12', change: this.changeCountry
        })
        this.fields.splice(this.getElementIndex(this.fields, 'multi_package'), 0, {
          fieldType: 'FieldSelect', label: this.$t('Tipo de contrato'), name: 'contract_type', useLabel: true, multiple: true, options: this.contractTypes, containerClass: 'col-md-4 container-info col-sm-12'
        })
        this.fields[this.getElementIndex(this.fields, 'place_id')].dependency = 'country'
        if (!!this.countries.length) this.setSelectOptions('country', { options: this.countries})
      } else {
        this.addCountryToForm()
        this.changeCountry('', this.formDeliveriesFilter.country)
      }      
      if (['marketplace', 'admin', 'superadmin'].includes(this.$session.get('cas_user')?.role)) {
        this.fields.splice(this.getElementIndex(this.fields, 'date_range') + 1, 0, {
          fieldType: 'FieldSelect', label: this.$t('Empresas'), name: 'company', allowIdSearch: true, persistSearch: true, searchOnType: { fx: e => this.searchCompany(e), nChars: 3, debounce: 600 }, containerClass: 'col-md-4 container-info col-sm-12', change: this.changeOrganization
        })
      }
      //!Auth::user()->storeProfile() && Auth::user()->role_id == 1)
      if (isAdmin && isNotStoreProfile) {
        this.fields.splice(this.getElementIndex(this.fields, 'service') + 1, 0, {
          fieldType: 'FieldSelect', label: this.$t('Macroestado'), name: 'meta_statuses', multiple: true, useLabel: true, containerClass: 'col-md-4 container-info col-sm-12', change: this.setFlowActions }, {
          fieldType: 'FieldSelect', useLabel: true, name: 'flow_action', label: this.$t('Estado'), multiple: true, change: this.validateShowCheckbox, containerClass: 'col-md-4 container-info col-sm-12'
        })
        this.$store.dispatch('fetchService', {name: 'getMacroStatesPlat'})
        this.$store.dispatch('fetchService', {name: 'getDeliveryStatusesFull'})
      }
      //!Auth::user()->storeProfile() && Auth::user()->role_id != 1 )
      if (!isAdmin && isNotStoreProfile) {
        this.fields.splice(this.getElementIndex(this.fields, 'service') + 1, 0, {fieldType: 'FieldSelect', useLabel: true, name: 'flow_action', label: this.$t('Estado'), multiple: true, containerClass: 'col-md-4 container-info col-sm-12'})
        this.$store.dispatch('fetchService', {name: 'getDeliveryStatusesFull'})
      }
      //!Auth::user()->storeProfile())
      if (isNotStoreProfile) {
        this.showExportBtn = true
      }
      this.changeOrganization(null)
    },
    searchCompany(value) {
      const queryParams = {
        search_by: value
      }
      return this.baseService.callService('getCompaniesPlatform', queryParams)
        .then(resp => {
          const response = resp.data
          const isAdmin = ['superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)
          response.map((row) => {
            if (row.marketplace === 1 && isAdmin) {
              row.name1 = `${row.name1} (M)`
            } else if (isAdmin && row.mkp_name1 !== null) {
              row.name1 = `${row.name1} - ${row.mkp_name1}`
            }
            //se convierte en string porue da error con los otros types del payload al agregarse como option
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.name1
          })
          return response
        })
        .catch(err => {
          return err
        }) 
    },
    changeOrganization (name, value) {
      this.validateShowCheckbox()
      this.cleanForm(false, ['carrier', 'sellers'])
      const isMKP = this.setIsMarketplace()
      //crear el input seller
      if (isMKP) {
        this.fields.splice(this.getElementIndex(this.fields, 'company') + 1, 0, {fieldType: 'FieldSelect', label: this.$t('Sellers'), dependency: 'company', searchOnType: { fx: e => this.searchSellers(e), nChars: 3, debounce: 600 }, name: 'sellers', multiple: true, containerClass: 'col-md-4 container-info col-sm-12'})
      }
      //Eliminar seller si no es mkp y existe el input
      if (!isMKP && this.getElementIndex(this.fields, 'sellers') !== -1) this.fields.splice(this.getElementIndex(this.fields, 'sellers'), 1)      
      this.searchCarrier(null)
    },
    searchCarrier (searchOnType) {
      const _ID = this.formDeliveriesFilter?.company?.id ? this.formDeliveriesFilter?.company.id : ['superadmin', 'admin'].includes(this.$session.get('cas_user')?.role) ? 10 : this.$session.get('cas_user')?.organization?.id
      const params = {
        company_id: _ID
      }
      const queryParams = {
        limit: 10
      }
      if (searchOnType) queryParams.text = searchOnType
      return this.baseService.callService('getCarriersPlatform', queryParams, params)
        .then(resp => {
          const response = resp.data
          response.map((row) => {
            if (['superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)) {
              row.name = `${row.carrier_name} (${row.dispatch_in})`
            }
            else {
              row.name = row.carrier_name
            }
            row.id = row.carrier_id            
            row.text = row.name
          })
          if (searchOnType) {
            return response
          } else {
            this.setSelectOptions('carrier', { options: response })
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    searchSellers (value) {
      const params = {
        company_id: this.formDeliveriesFilter.company.id
      }
      const queryParams = {
        search_by: value
      }
      return this.baseService.callService('getSellersPlat', queryParams, params)
        .then(resp => {
          const response = resp.data
          response.map((row) => {
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.name1
          })
          return response
        })
        .catch(err => {
          return err
        }) 
    },
    searchPudos(value) {
      const queryParams = {
        search_by: value
      }
      return this.baseService.callService('getPudosPlat', queryParams)
        .then(resp => {
          const response = resp.data
          response.map((row) => {
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = `${row.name} (${row.code})`
          })
          return response
        })
        .catch(err => {
          return err
        }) 
    },
    changeCountry (name, value) {
      this.cleanForm(false, ['place_id', 'place_level_1', 'zip_code'])
      if (!value) { 
        this.cleanPlacesInput()
        this.changeTextI18n('CL', 'place_id', 'Place_id')
        this.keyFormRender++
        return
      }
      //Capturar los index para poder mostrar u ocultar a voluntad
      const countryIndex = this.isAdmin ? this.getElementIndex(this.fields, 'country') + 1 : this.getElementIndex(this.fields, 'pudo') + 1
      // Se muestra para CO, PE, AR, EC
      if (value.id !== this.ID.CHILE && value.id !== this.ID.MEXICO) {
        //En caso de que no este el input se crea
        if (this.getElementIndex(this.fields, 'place_level_1') === -1) {
          this.fields[this.getElementIndex(this.fields, 'place_id')].dependency = 'place_level_1'
          const fieldType =  { 
            fieldType: 'FieldSelect', 
            useLabel: true, name: 'place_level_1',
            searchOnType: { fx: e => this.searchInPlaceId(e, null, true), nChars: 3, debounce: 600, persistSearch: true }, 
            containerClass: 'col-12 col-md-4 fieldSelect' 
          }
          if (this.isAdmin) fieldType.dependency = 'country'
          this.fields.splice(countryIndex, this.getElementIndex(this.fields, 'zip_code') !== -1 ? 1 : 0, fieldType)
        }
        delete this.fields[this.getElementIndex(this.fields, 'place_id')].options
        //Se hacen las traducciones por pais
        this.changeTextI18n(value.code, 'place_level_1', value.id === this.ID.PERU ? 'Place_level2' : 'Place_level1')
      }
      else if (value.id === this.ID.MEXICO && this.getElementIndex(this.fields, 'zip_code') === -1) {
        //Eliminamos esta propiedad ya q para mexico se llena con el zipcode
        delete this.fields[this.getElementIndex(this.fields, 'place_id')].searchOnType
        // la condicion en 2do. param del splice es por si existe el place_level_1 sea reemplazado por el zip_code, de lo contrario se agrega un input nuevo
        this.fields.splice(
          countryIndex,
          this.getElementIndex(this.fields, 'place_level_1') === -1 ? 0 : 1,
          {fieldType: 'FieldSelect', useLabel: true, name: 'zip_code', label: this.$t('Código Postal'), dependency: 'country', change: this.handleChangeZipCode, searchOnType: { fx: e => this.searchInPlaceId(e, true), nChars: 3, debounce: 600, persistSearch: true }, containerClass: 'col-12 col-md-4 fieldSelect'}
        )
        this.fields[this.getElementIndex(this.fields, 'place_id')].dependency = 'zip_code'
      }
      else if (value.id === this.ID.CHILE) {
        //Eliminar los inputs en caso de que existan
        this.cleanPlacesInput()
      }
      //Si se ha eliminado el searchOntype y porq se selecciono mexico y luego se cambio, lo agregamos
      if (value.id !== this.ID.MEXICO && !this.fields[this.getElementIndex(this.fields, 'place_id')].searchOnType) this.fields[this.getElementIndex(this.fields, 'place_id')].searchOnType = this.placeSearchFn
      //Esto siempre se traduce porque esta para todos los paises
      this.changeTextI18n(value.code, 'place_id', 'Place_id')
      this.keyFormRender++
    },
    cleanPlacesInput () {
      if (this.getElementIndex(this.fields, 'place_level_1') !== -1) this.fields.splice(this.getElementIndex(this.fields, 'place_level_1'), 1)
      if (this.getElementIndex(this.fields, 'zip_code') !== -1) { 
        this.fields.splice(this.getElementIndex(this.fields, 'zip_code'), 1)
      }
      const indexPLaceID = this.getElementIndex(this.fields, 'place_id')
      delete this.fields[indexPLaceID].options
      this.fields[indexPLaceID].dependency = 'country'
      if (!this.fields[indexPLaceID].searchOnType) this.fields[indexPLaceID].searchOnType = this.placeSearchFn
    },
    /**
   * Para hacer la busqueda de los places desde los diferentes inputs
   * @param {string} value texto ingresado
   * @param {boolean} hasZipCode Esto es para cuando se llama desde el input del zipcode
   * @param {boolean} isFromPlaceLevel Esto es para cuando se llama desde el input del place_level_1
   */
    searchInPlaceId(value, hasZipCode = false, isFromPlaceLevel = false) {
      const countryID = this.formDeliveriesFilter.country.id
      const params = {
        country_id: countryID
      }
      const queryParams = {
        str: value
      }
      //Agregar otros parametros en caso de que no se llame desde place_level_1 para AR, CO, EC, PE
      if (countryID !== this.ID.CHILE && countryID !== this.ID.MEXICO) {
        if (isFromPlaceLevel) {
          queryParams.level = this.$options.filters.countryLevels()[countryID]
        } else {
          queryParams.parent = this.formDeliveriesFilter.place_level_1.id
        }
      }      
      const serviceName = !hasZipCode ? 'getPlacesCompanies' : 'getZipcodeCompanies'
      return this.baseService.callService(serviceName, queryParams, params)
        .then(resp => {
          const response = hasZipCode ? resp.data.zip_codes.map(val => ({'name': val, 'id': val})) : resp.data //El array se convierte en objeto
          response.map((row) => {
            //se convierte en string porue da error con los otros types del payload al agregarse como option
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.name
          })
          return response
        })
        .catch(err => {
          return err
        })
    },
    /**
    * Para llenar las opciones del input place_id para mexico, ya que se llena automatico dependiendo del zipcode seleccionado
    */
    handleChangeZipCode(name, value) {
      const params = {
        country_id: this.formDeliveriesFilter.country.id
      }
      const queryParams = {
        'str': '',
        'zip-code': value.name
      }
      this.baseService.callService('getPlacesCompanies', queryParams, params)
        .then(resp => {
          const response = resp.data
          response.map((row) => {
            //se convierte en string porue da error con los otros types del payload al agregarse como option
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.name
          })
          this.setSelectOptions('place_id', { options: response })
        })
        .catch(err => {
          console.error(err)
        })      
    },
    /**
    * Como llega paginado no se mostraran todos los servicios al seleccionar el carrier, es por esto se hace una busqueda tambien al escribir
    * @param {string} value valor escrito
    */
    searchService(value) {
      const params = {
        carrier_id: this.formDeliveriesFilter.carrier.id
      }
      const queryParams = {
        search_by: value
      } 
      return this.baseService.callService('getServiceByCarrier', queryParams, params)
        .then(resp => {
          const response = resp.data
          response.map((row) => {
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.name
          })
          return response
        })
        .catch(err => {
          return err
        })
    },
    handleCarrierChange (name, value) {
      this.validateShowCheckbox()
      //Limpiar el input de la dependencia
      if (!value) {
        delete this.formDeliveriesFilter.service
        return
      }
      const services = value.services
      services.map((row) => {
        row.text = row.name
        row.id = row.code
      })
      this.setSelectOptions('service', { options: services })
    },
    setFlowActions (name, value) {
      if (!value) return
      const allID = value.map(id => id.ids).flat(1)
      const actionsGroup = allID.map(key => this.deliveryStatuses.filter(status => status.id === key)[0])
      if (this.formDeliveriesFilter.flow_action) {
        const notRepeatVal = this.formDeliveriesFilter.flow_action.concat(actionsGroup.filter(({ name }) => !this.formDeliveriesFilter.flow_action.some((e) => e.name === name)))
        this.formDeliveriesFilter.flow_action = notRepeatVal
      } else {
        this.formDeliveriesFilter.flow_action = actionsGroup
      }
      this.keyFormRender++
    },
    validateShowCheckbox () {
      // ng-show="filter.company && filter.flow_action && filter.flow_action.length > 0 || filter.carrier  && filter.flow_action && filter.flow_action.length > 0 "
      if (['superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)) {
        const conditionToShow = (this.formDeliveriesFilter?.company && this.formDeliveriesFilter?.flow_action && this.formDeliveriesFilter.flow_action?.length > 0) || (this.formDeliveriesFilter?.carrier && this.formDeliveriesFilter?.flow_action && this.formDeliveriesFilter.flow_action?.length > 0)
        if (conditionToShow && this.getElementIndex(this.fields, 'later_status') === -1) {
          this.fields.push({
            fieldType: 'FieldCheckbox', name: 'later_status', options: [
              { id: 1, text: 'Pinche aquí para buscar el estado seleccionado en estados anteriores del envío.' }
            ], containerClass: 'col-12 container-info'
          })
        }
        else if (!conditionToShow && this.getElementIndex(this.fields, 'later_status') !== -1) { 
          this.fields.splice(this.getElementIndex(this.fields, 'later_status'), 1)
        }       
      }
    },
    exportData (allForm) {
      if (!!allForm.flow_action) {
        allForm.status = allForm.flow_action
        delete allForm.flow_action
      }
      const queryParams = {...allForm}
      delete queryParams.date_range
      const config = {
        fullResponseError: true
      }
      this.baseService.callService('getDeliveriesReportPlat', queryParams, null, config)
        .then(({data}) => {      
          if (data.status === 'queued') {
            this.$emit('showAlert', data.message, 'Atención', 'warning')
            this.errorMsg = data.message
            this.ErrorDownloadLink = true
            this.loading.exporting = false
          } else {
            this.downloadName = data.filename || 'Envíos-'
            this.getExportDocURl(data.url)
          }
          
        })
        .catch(err => {
          this.$emit('showAlert', err.data?.message || '¡Oops!', this.$t('msg-problema-generacion-reporte'), 'danger')
          this.errorMsg = err.data?.message || 'Hubo un error con la exportación'
          this.ErrorDownloadLink = true
          this.loading.exporting = false
        })
    },
    getExportDocURl(url) {
      const queryParams = {
        path: url
      }
      this.baseService.callService('getDeliveriesReportURLPlat', queryParams, null, { fullResponse: true })
        .then(({data}) => {
          this.linkToDownload = URL.createObjectURL(data)
          this.$emit('showAlert', this.$t('msg-exito-generacion-reporte'), '¡Felicitaciones!', 'success')
          this.$emit('setLink', this.linkToDownload, this.downloadName)
        })
        .catch(err => {
          this.$emit('showAlert', this.$t('msg-problema-generacion-reporte'), '¡Oops!', 'danger')
          this.errorMsg = err.data?.message || 'Hubo un error con la exportación'
          this.ErrorDownloadLink = true
        })
        .finally(() => {
          this.loading.exporting = false
        })
    },
    setIsMarketplace () {
      if (['superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)) {
        return this.formDeliveriesFilter?.company ? Number(this.formDeliveriesFilter.company.marketplace) : 0
      }  else {
        return 0
      }
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },    
    changeTextI18n(countryCode, elementName, key) {
      const texts = this.$i18nImport(`${countryCode.toLowerCase()}.es`)[`${countryCode.toLowerCase()}.es`]
      const index = this.fields.findIndex(el => el.name === elementName)
      this.fields[index].label = this.$t(texts[key])
    },
    getElementIndex(array, value) {
      return array.findIndex(field => field.name === value)
    },
    cleanForm(fullClean, keys = Object.keys(this.formDeliveriesFilter)) {
      if (this.ErrorDownloadLink) this.ErrorDownloadLink = false
      keys.forEach(key =>  {
        delete this.formDeliveriesFilter[key]
      })
      this.addCountryToForm()
      if (fullClean) this.setInitialData() //Se refresque el formulario con los inpurts por defecto
    },
    addCountryToForm () {
      if (!['superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)) this.formDeliveriesFilter.country = this.$session.get('cas_user')?.organization?.country
    },
    close() {
      this.$bvModal.hide('modalAvancedFilterDeliveries')
    },
    ok(e, fromExport) {
      this.$emit('filter', this.formDeliveriesFilter, fromExport)
      //Evitar que haga filtro mientras exporta y cierre el modal
      if (fromExport) {
        this.loading.exporting = true
        if (this.ErrorDownloadLink) this.ErrorDownloadLink = false
      } else {
        this.close()
      }
    }
  }
}
</script>
<style>

</style>
